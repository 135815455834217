@import "tailwindcss";
@import "loader.scss";
@config "./tailwind.config.js";


/* Animations */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate_left {
  from {
    transform: rotate(16deg);
  }
  to {
    transform: rotate(376deg);
  }
}

@keyframes rotate_right {
  from {
    transform: rotate(4deg);
  }
  to {
    transform: rotate(364deg);
  }
}

.mapboxgl-ctrl button:not(:disabled):hover {
  @apply hover:bg-gray-50;
  color: white;
}

.satellite-toggle {
  width: 29px;
  height: 29px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='85.333' height='85.333' version='1.0' viewBox='0 0 64 64'%3E%3Cpath d='M38.5 9c-6.4 6.4-6.9 7.2-5.7 9.1 1 1.6 1.1 2.4.1 3.5-1 1.3-1.8 1-4.9-2.1-3.3-3.2-3.8-3.4-5-2-.7.8-2 1.4-2.9 1.3-1-.2-1.5.3-1.3 1.3.1.9-.5 2.2-1.3 2.9-1.4 1.2-1.2 1.7 2 5 3.1 3.1 3.4 3.9 2.1 4.9-1.1 1-1.9.9-3.5-.1-1.9-1.2-2.7-.7-9.3 5.9L1.6 46l3.9 4c2.1 2.2 4.4 4 5 4 .6 0 4.2-3.1 8-7 6.7-6.7 6.8-7 5.1-8.9-1.8-2-1.5-4.1.7-4.1.6 0 2.6 1.4 4.4 3.2 3.2 3.1 3.3 3.1 5.3 1.3 2.1-1.9 2-2.1 3.6 3.3.7 2.2 4.9 1 5.2-1.6.2-1.2-.3-2.4-1-2.6-5.4-1.6-5.2-1.5-3.3-3.6 1.8-2 1.8-2.1-1.3-5.3-1.8-1.8-3.2-3.8-3.2-4.4 0-2.2 2.1-2.5 4.1-.7 1.9 1.7 2.2 1.6 8.9-5.1 3.9-3.8 7-7.4 7-8C54 9.4 46.9 2 45.9 2c-.2 0-3.6 3.2-7.4 7zm10.9-1.2c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zm-3.5 8.7c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zm-5.5.3c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zM34.5 35l-3 3-6.5-6.5-6.4-6.5 2.9-3 2.9-3.1 6.5 6.6 6.6 6.5-3 3zm-13.6 6.5c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zm-5.5.3c2.4 2.6 2.5 3 1.1 4.2-1.3 1.1-2 .9-4.5-1.5-2.3-2.2-2.7-3.2-1.9-4.2 1.6-1.8 2.2-1.7 5.3 1.5zM41 40c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1zM11.9 50.5c-1.3 1.4-1.8 1.2-4.4-1.4-2.4-2.6-2.6-3.3-1.5-4.6 1.2-1.5 1.7-1.3 4.4 1.4 2.7 2.8 2.9 3.2 1.5 4.6zM47.5 40.5c-1.5 4.6-2.3 5.3-7 7.1-1.9.7-1.9.8.3 1.2 3 .5 7-2.2 7.8-5.3.8-3.2-.1-5.8-1.1-3zM53.1 43.3c-1.3 5.3-5.3 9.1-10.7 10.1-2.5.5-3.3 1.1-2.6 1.8 1.3 1.3 7-.5 10.6-3.3 3-2.4 6.4-10.5 4.9-12-.7-.7-1.4.4-2.2 3.4z'/%3E%3Cpath d='M59.6 43.2c-1.3 8.5-7.9 15-16.4 16.4-4.7.7-5.7 2.4-1.4 2.4 3.7-.1 10.1-3.1 13.6-6.6 3.5-3.5 6.5-9.9 6.6-13.7 0-4.2-1.7-3.2-2.4 1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%; /* Changed from 'contain' to '80%' */
  cursor: pointer;
}

.satellite-toggle.satellite-active {
  background-color: #ddd;
}

.cdk-overlay-container{
  z-index:9999 !important;
}


#sentry-feedback {
  --inset: auto auto 0 0;
}
